/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($, window) {

  // render mobile and tablet ads
  var Ads = {

    loadAds: function () {
      var mobileSlots = ['5713435665', '7190168862'];
      var tabletSlots = ['4097101662', '7050568060'];
      var md = new MobileDetect(window.navigator.userAgent);

      if( $('article.post .entry-content p').length > 0 ) {
        if( md.mobile() ) {
          // Insert mobile ad after first/last paragraph in article
          // these render on tablet devices, but are hidden using media queries
          $('article.post .entry-content p').first().after(this.buildMobileAd(mobileSlots[0], 'mobile-atf'));
          $('article.post .entry-content p').last().before(this.buildMobileAd(mobileSlots[1], 'mobile'));
        }
        if( md.tablet() ) {
          $('article.post .entry-content p').eq(3).after(this.buildTabletAd(tabletSlots, 'tablet'));
        }
      }
    },

    buildMobileAd: function(slot, css) {
      return '<div class="mobile-ad-wrap advertisement">' + this.buildInsert(slot, css) + '</div>';
    },

    buildTabletAd: function(slots, css) {
      var adLeft = '<div class="left-ad">' + this.buildInsert(slots[0], css) + '</div>';
      var adRight = '<div class="right-ad">' + this.buildInsert(slots[1], css) + '</div>';

      return '<div class="tablet-ad-wrap advertisement"><h2>Continue Reading Below</h2>' + adLeft + adRight + '</div>';
    },

    buildInsert: function(slot, css) {
      return '<ins class="adsbygoogle ' + css + '" data-ad-client="ca-pub-7651301038589795" data-ad-slot="' + slot + '" data-ad-format="auto"></ins>';
    }
  };


  // Use this variable to set up the common and page specific functions.
  var PetsBeingAwesome = {
    'common': {
      init: function() {
        // Open mobile menu
        $('.mobile-icon, .black-overlay').click(function() {
           $('body').toggleClass('nav-open');
        });
      },
      finalize: function() {
      }
    },
    'home': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'single': {
      init: function() {
        /**
         * Header Ad/Share animation
         */
        var lastScrollTop = 0;

        $(window).scroll( $.throttle( 200, function() {
          var pos = $(window).scrollTop();

          if( $('body').hasClass('single') && pos > lastScrollTop  && pos > 100) {
            $('body > header').addClass('is-secondary');
          } else {
            $('body > header').removeClass('is-secondary');
          }
          lastScrollTop = pos;
        }));

          Ads.loadAds();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = PetsBeingAwesome;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);

})(jQuery, window);